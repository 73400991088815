import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { siteMetadata } from "../../gatsby-config"
import { FacebookProvider, Like } from "react-facebook"
import Sharer from "../components/sharer"
import Box from "@material-ui/core/Box"
import Chip from "@material-ui/core/Chip"
import appstoreBadge from "../../static/assets/app_store_badge.png"
import googleplayBadge from "../../static/assets/google_play_badge.png"
import apkDownloadBadge from "../../static/assets/apk_download_badge.png"
import {sluger} from "../util/sluger"

const Pagination = props => (
  <div className="pagination-strict">
    <div className="pagination -post">
      <ul>
        {props.previous && 
          props.previous.frontmatter.template === "app-preview" && (
            <li>
              <Link to={props.previous.frontmatter.slug ? props.previous.frontmatter.slug : sluger(props.previous.frontmatter.title,"/apps/")} rel="prev">
                <p>
                  <span className="icon -left">
                    <RiArrowLeftLine />
                  </span>{" "}
                  Previous
                </p>
                <span className="page-title">
                  {props.previous.frontmatter.title}
                </span>
              </Link>
            </li>
          )}
        {props.next && props.next.frontmatter.template === "app-preview" && (
          <li>
            {props.next.frontmatter.date != null ? (
              <Link to={props.next.frontmatter.slug ? props.next.frontmatter.slug : sluger(props.next.frontmatter.title,"/apps/")} rel="next">
                <p>
                  Next{" "}
                  <span className="icon -right">
                    <RiArrowRightLine />
                  </span>
                </p>
                <span className="page-title">
                  {props.next.frontmatter.title}
                </span>
              </Link>
            ) : (
              ""
            )}
          </li>
        )}
      </ul>
    </div>
  </div>
)

const DownloadBadges = ({ data }) => {
  const { google_play_link, appstore_link, apk_link } = data
  return (
    <div>
      {(google_play_link) ? <a href={google_play_link} target="_blank" rel="noopener noreferrer"><img
        src={googleplayBadge}
        alt="download in googleplay"
        className="download-badge"
      /> </a>: ""}
      {(appstore_link) ?  <a href={appstore_link} target="_blank"  rel="noopener noreferrer"><img
        src={appstoreBadge}
        alt="download in appstore"
        className="download-badge"
      /></a> : ""}
      {(apk_link && !google_play_link ) ? <a href={apk_link} target="_blank"  rel="noopener noreferrer"><img
        src={apkDownloadBadge}
        alt="download apk"
        className="download-badge"
      /></a> : ""}
    </div>
  )
}

const Post = ({ data, pageContext, location }) => {
  if (data.markdownRemark == null) return ""

  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  const Image = frontmatter.appIcon
    ? frontmatter.appIcon.childImageSharp.fluid
    : ""
  const ImageSEO = frontmatter.appIcon
    ? frontmatter.appIcon.childImageSharp.resize
    : ""
  const { previous, next } = pageContext
  // const currentURL = typeof window !== `undefined` ? window.location.protocol + "//" + window.location.host + window.location.pathname : ""
  const {siteUrl} = siteMetadata
  const currentSlug = frontmatter.slug ? frontmatter.slug : sluger(frontmatter.title,"/apps/")
  const currentURL = `${siteUrl}${currentSlug}`

  const chips = frontmatter.tools?.map(element => (
    <Chip key={element} label={element} className="chip" variant="outlined" size="small" />
  ))

  let props = {
    previous,
    next,
  }

  return (
    <Layout className="page">
      <SEO
        title={frontmatter.title + " - Apps in Meek Code"}
        description={
          frontmatter.description ? frontmatter.description : excerpt
        }
        image={ImageSEO.src}
        article={true}
      />
      <article className="blog-post">
        <header className="featured-banner"></header>
        <div className="app-sharer">
          <Sharer url={currentURL} title={frontmatter.title} />
        </div>

        <div className="app-sub-header">
          <div className="app-preview-image">
            {Image ? (
              <Img
                fluid={Image}
                objectFit="cover"
                objectPosition="50% 50%"
                alt={frontmatter.title + " - app icon"}
                className="featured-app-icon"
              />
            ) : (
              ""
            )}
          </div>
          <Box flexDirection="column" className="app-content">
            <div className="title-like">
              <h1>{frontmatter.title}</h1>
              <FacebookProvider appId={siteMetadata.facebook_appID}>
                <Like href={currentURL} colorScheme="dark" showFaces />
              </FacebookProvider>
            </div>
            <div className="app-download-badges -header">
              <DownloadBadges data={frontmatter} />
            </div>
          </Box>
          </div>
        <div className="language-tool-header">
          <div className="language">
            <div className ="headerText">Language:</div>
            <div className="valueText">{frontmatter.language}</div>
          </div>
          {chips ? (
            <div className="tools">
              <h4>Tools/Framework/Engine:</h4>
            </div>
          ) : (
            ""
          )}
          {chips}
        </div>
  
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <div className="app-download-badges -footer">
          <DownloadBadges data={frontmatter} />
        </div>
      </article>
      {(previous || next) && <Pagination {...props} />}
    </Layout>
  )
}

export default Post

export const appQuery = graphql`
  query AppPrevQuery($id: String!) {
    markdownRemark(id: { eq: $id } ) {
      id
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        slug
        description
        language
        tools
        google_play_link
        appstore_link
        apk_link
        appIcon {
          childImageSharp {
            resize(width: 200, height: 200) {
              src
              height
              width
            }
            fluid(maxWidth: 192, maxHeight: 192, quality: 80) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
            sizes {
              src
            }
          }
        }
      }
    }
  }
`
